<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
  >
    <ContactForm
      :contact="newContact"
      :errors="contactValidationErrors"
      :disabled="$store.getters.loading['post:api/clients']"
      @clear:errors="CLEAR_CONTACT_VALIDATION_ERRORS"
      @cancel="onCancel"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ContactForm from '@/components/forms/ContactForm';

export default {
  name: 'CreateContact',

  components: { ContactForm },

  computed: {
    ...mapState('contacts', ['newContact', 'contactValidationErrors', 'contactFilterParams']),
  },

  created() {
    this.SET_CONTACT_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('contacts', ['storeContact']),
    ...mapMutations('contacts', [
      'SET_NEW_CONTACT',
      'SET_CONTACT_VALIDATION_ERRORS',
      'CLEAR_CONTACT_VALIDATION_ERRORS',
    ]),

    onCancel(contact) {
      this.SET_NEW_CONTACT(contact);
      this.goBack();
    },

    onSave(contact) {
      this.storeContact(contact).then((createdContact) => {
        this.$emit('contact-created', createdContact);
        this.goBack();
      });
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute());
    },
  },
};
</script>
